@font-face{
	font-family: Euro;
	src: url('~assets/fonts/EUROBLACK.ttf');
}
@font-face{
	font-family: EuroExBlack;
	src: url('~assets/fonts/EUROEXTENDEDBLACK.ttf');
}
@font-face{
	font-family: ExoMed;
	src: url('~assets/fonts/EXO-MEDIUM.TTF');
}
@font-face{
	font-family: ExoReg;
	src: url('~assets/fonts/EXO-REGULAR.TTF');
}
@font-face{
	font-family: RobotoBold;
	src: url('~assets/fonts/ROBOTO-BOLD.TTF');
}
@font-face{
	font-family: RobotoMed;
	src: url('~assets/fonts/ROBOTO-MEDIUM.TTF');
}
@font-face{
	font-family: RobotoReg;
	src: url('~assets/fonts/ROBOTO-REGULAR.TTF');
}
