.about_tab{
	padding: 1% 0;
	@media screen and (max-width: $mobile){
		padding: 5% 0;
	}
	.tab_title,.tab_content{
		display: flex;
		justify-content: space-between;
		align-items: center;

		div{
			&.active{
				display: block;
			}
		}
		
		.tab-item{
			border: 1px solid $accent-dark;
			display: grid !important;
			place-items: center;

			@media screen and (max-width: $mobile){
				height: 4rem;
			}

			&.active{
				display: block;
			}
		}
	}

	.tab_title{
		div{
			width: calc(100%/4);
			text-align:  center;
			text-transform: capitalize;
			padding: 1%;
			font-family: ExoMed;
			position: relative;
			cursor: pointer;
			&:before{
				content: '';
				width: 1px;
				height: 80%;
				display: inline-block;
				position: absolute;
				right: 100%;
				top: 50%;
				transform: translateY(-50%);
			}
			&:first-child{
				&:before{
					display: none;
				}
			}

			&.active,&:hover{
				color: $white;
				background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 1%, $accent-dark 20%);
				&:before{
					display: none;
				}
			}
		}
	}

	.tab_content{
		margin-top: 3%;
		height: 20vw;
		@media screen and (max-width: $mobile){
			height: auto;
		}

		>div{
			height: 100%;
			width: 100%;
			display: none;
		}

		.qualifier{
			height: 100%;
			display: flex;
			justify-content: space-between;

			@media screen and (max-width: $mobile){
				display: block;
				width: 100%;
			}

			.img_bracket{
				width: 55%;
				height: 100%;
				@media screen and (max-width: $mobile){
					width: 100%;
				}
				.toggle_bracket{
					height: 15%;
					margin-bottom: 2%;
					display: flex;
					align-items: center;
					width: 100%;
					border: 1px solid $accent-dark;
					@media screen and (max-width: $mobile){
						height: 10vw;
					}
					.arrow{
						height:100%;
						width: 10%;
background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 1%, $accent-dark 20%);
						position: relative;
						cursor: pointer;
						img{
							width: 80%;
							position: absolute;
							top: 50%;
							left: 0;
							right: 0;
							margin: 0 auto;
							transform: translateY(-50%) rotate(90deg);
						}

						&.right{
							img{
								transform: translateY(-50%) rotate(-90deg);
							}
						}
					}
					p{
						width: 80%;
						text-align: center;
						font-family: ExoMed;
						text-transform: capitalize;
					}
				}
				.img{
					width: 100%;
					// height: 83%;
					border: 1px solid grey;
					@media screen and (max-width: $mobile){
						// height: 25vw;
						width: 100%;
					}
				}
			}

			.text{
				height: 100%;
				width: 40%;
				@media screen and (max-width: $mobile){
					width: 100%;
				}
				&_title{
					font-family: Euro;
					color: $accent; 
					font-size: 1.3vw;
					padding-bottom: 5%;
					@media screen and (max-width: $mobile){
						display: none;
					}
					&.mobile{
						display: none;
						@media screen and (max-width: $mobile){
							display: block;
							font-size: 6vw;
							text-align: center;
							padding: 3% 0 5%;
						}	
					}
				}
				&_content{
					height: 80%;
					overflow: auto;
					font-size: .9vw;
					padding-right: .6vw;
					@media screen and (max-width: $mobile){
						height: 20vh;
						font-size: 3.5vw;
						margin-top: 5%;

					}
					/* width */
					&::-webkit-scrollbar {
					  width: .6vw;
					}

					/* Track */
					&::-webkit-scrollbar-track {
					  background: transparent;
					  border: 1px solid #707070;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
					  background: $accent;
					}

					p{
						margin-top: 5%;
						line-height: 1.4;
						&:first-child{
							margin-top: 0;
						}						
					}
				}
			}
		}
	}
}
