.header{
	background: $navy;
	position: fixed;
	top: 0;
	width: 100%;
	border-bottom: 2px solid $accent;
	z-index: 1000;
	height: 5vw;
	@media screen and (max-width: $mobile){
		height: 15vw;
	}

	&_cont{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 60%;
		margin: 0 auto;
		@media screen and (max-width: $mobile){
			width: 90%;
		}

		.back{

		}

		.header_text{
			width: 80%;
			font-size: 1.5vw;
			@media screen and (max-width: $mobile){
				font-size: 5vw;
				width: 70%;
			}
		}
		.acc{
			padding:0;
			.subnav{
				top: 85%;
			}
		}
	}
}