.teams, .teams2{
	padding: 4% 0 !important;
	@media screen and (max-width: $mobile){
		padding: 5% 7% !important;
	}

	.title{
		padding: 0;
		a{
			right: 7%;
		}

		@media screen and (max-width: $mobile){
			padding: 0;
			a{
				right: 0;
			}
		}
	}

	&_content{
		margin: 2% 0;
		width: 100%;
		overflow: auto;
	}
	&_list{
		display: flex;
		justify-content: center;
		align-content: flex-start;
		flex-wrap: wrap;
		gap: 1.5vw;

		@media screen and (max-width: $mobile){
			overflow-x: auto;
			overflow-y: hidden;
			white-space: nowrap;
			gap: 1.5vh 4vw;
		}
		
		&::-webkit-scrollbar {
		  height: 0;
		}

		.box_teams{
			width: calc(100%/5.44);
			height: 12vw;
			text-align: center;
			display: inline-block;
			background: url('~assets/img/Container-Box_teams.png') no-repeat;
			background-size: 100% 100%;
			position: relative;
			// margin-top: 9px;
			padding: 1%;
			
			&:last-child{
				margin-right: 0;
			}

			&.playoff {
				width: calc(100%/5.44);
				height: 12vw;

				// &:nth-of-type(n+6){
				// 	margin-top: 2%;
				// }
	
				&:last-child, &:nth-of-type(5n){
					margin-right: 0;
				}
			}

			@media screen and (max-width: $mobile){
				width: 40% !important;
				height: 33vw !important;
				// margin-right: 5%;
			}

			.box_cont{
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.img_logo{
					height: 80%;
					width: 75%;
					position: relative;
					margin: 0 auto;
					text-align: center;
					>img{
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						margin: auto;
						transform: translateY(-50%);
					}
				}
				.name{
					height: 20%;
					width: 100%;
					display: table;
					span{
						display: table-cell;
						vertical-align: middle;

						@media screen and (max-width: $mobile){
							font-size: 2.6vw;
						}
					}
				}				
			}

		}
	}

}
