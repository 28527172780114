@import 'partials/fonts';
@import 'partials/variable';

* {
    box-sizing: border-box;
}

body {
    width: 100%;
    margin: 0;
    font-family: RobotoReg;
    background: $navy;
	color: $white;
    font-size: 1vw;
    @media screen and (max-width: $mobile){
	   font-size: 3.5vw;
    }
    .img-fluid{
    	max-width: 100%;
        max-height: 100%;
    	height: auto;
    	vertical-align: middle;
    }
    p{
        margin: 0;
    }

}

.content-container{
    padding-top: 5vw;

    @media screen and (max-width: $mobile){
        padding: 0;
     }

    &.nopadd{
        padding-top: 0;
    }

    .gradient{
        position: absolute;
        width: 100%;
        height: 60%;
        bottom: -40%;
        @media screen and (max-width: $mobile){
            bottom: -40%;
        }
    }
}

.default_schedule{
	width: 100%;
	display:block;
	img{
		max-width:100%;
		height:auto;
		vertical-align:middle;
	}
}

@import 'partials/popup';
@import 'partials/navbar';
@import 'partials/header';
@import 'partials/footer';
@import 'pages/home/index';
@import 'pages/regis/index';
@import 'pages/about';
@import 'pages/rules';
@import 'pages/schedule';
@import 'pages/teams';
