.footer{
	border-top: 1px solid $accent;
	position: relative;
	padding: 3% 0 5%;
	@media screen and (max-width: $mobile){
		padding: 10% 0;
	}
	&:before{
		content: '';
		width: 100%;
		height: 1px;
		background: $accent;
		position: absolute;
		top: 5%;
	}
	&_content{
		width: 45%;
		margin: 1% auto;
		text-align: center;
		@media screen and (max-width: $mobile){
			width: 90%;
		}
		>span{
			font-family: Euro;
			font-size: 1.7vw;
			@media screen and (max-width: $mobile){
				font-size: 5vw;
			}
		}
		ul{
			list-style-type: none;
			display: flex;
			justify-content: center;
			padding: 0;
			margin: 2% 0;
			li{
				margin-left: 10%;
				
				&:first-child{
					margin-left: 0;
				}

				img {
					height: 85%;
				}
			}
			@media screen and (max-width: $mobile){
				margin: 5% 0;
				li{
					width: 50%;
				}
			}
		}
		.copyright{
			padding: 0 5%;
			font-size: .9vw;
			@media screen and (max-width: $mobile){
				font-size: 3vw;
			}
		}
	}
}
