.home{
	padding-top: 5vw;
	
	&_content{
		width: 55%;
		margin: 0 auto;
    background: url("../../../img/Ornate_Video.png") top center no-repeat;
    background-size: contain;
		@media screen and (max-width: $mobile){
			width: 100%;
		}

		.detail{
			border-top: 1px solid $accent;
			&:first-child{
				border-top: 0;
			}

			&_container{
				padding: 4% 7%;
				@media screen and (max-width: $mobile){
					padding: 5% 7%;
				}
				&.rules{
					padding: 1% 7%;
					@media screen and (max-width: $mobile){
						padding: 5% 0;
					}
				}
				.title{
					font-family: Euro;
					font-size: 1.7vw;
					position: relative;
					@media screen and (max-width: $mobile){
						font-size: 6vw;
					}
					p{
						font-family: ExoReg;
						font-size: 1vw;
						@media screen and (max-width: $mobile){
							font-size: 4vw;
						}
					}
					a{
						position: absolute;
						right: 0;
						font-size: .9vw;
						color: $white;
						text-decoration: none !important;
						top: 50%;
						transform: translateY(-50%);
						font-family: ExoReg;
						@media screen and (max-width: $mobile){
							font-size: 3vw;
						}
					}
				}
				.rules_container{
					position: relative;
					text-align: center;
					background: url('~assets/img/Orante_DownloadRules.png') no-repeat center center;
					background-size: 50%;
					padding: 1% 0;
					@media screen and (max-width: $mobile){
						text-align: left;
						background-size: 100%;
						background-position: center;
					}
					.rules_content{
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						@media screen and (max-width: $mobile){
							display: block;
							padding: 0 7%;
						}
						.title{
							width: 30%;
							text-align: left;
							@media screen and (max-width: $mobile){
								width: 100%;
							}
						}
						.btn_rules{
							color: $white;
							background: rgb(255,255,255);
							background: linear-gradient(180deg, rgba(255,255,255,1) 1%, $accent-dark 20%);
							text-decoration: none !important;
							text-transform: uppercase;
							padding: 1.5% 3%;
							display: inline-block;
							border-radius: 5px;
							font-family: ExoMed;
							font-weight: bold;
							letter-spacing: 1px;
							margin-left: 5%;
							@media screen and (max-width: $mobile){
								margin-left: 0;
								margin-top: 3%;
								font-size: 4.5vw;
							}
						}
					}
				}

			}
		}
	}

}
@import './section1';
@import './video';
@import './about';
@import './standing';
@import './schedule';
@import './teams';
@import './news';
