.navbar{
	background: $navy;
	position: fixed;
	top: 0;
	width: 100%;
	border-bottom: 2px solid $accent-dark;
	z-index: 1000;
	height: 5vw;

	@media screen and (max-width: $mobile){
		height: initial;
	}

	.nav_desktop{
		width: 60%;
		margin: 0 auto;
		height: 100%;
		@media screen and (max-width: $mobile){
			display: none;
		}
		ul{
			padding: 0;
			list-style-type: none;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			text-align: center;
			margin: 0;
			height: 100%;
			li{
				width: calc(100%/7);
				height: 100%;
				border-bottom: .2vw solid transparent;
				display: table;
				a{
					display: table-cell;
					vertical-align: middle;
					padding: 0 5%;
					// display: block;
					width: 100%;
					color: $white;
					text-decoration: none !important;
				}
				&:hover, &.active{
					border-bottom: .2vw solid $accent-dark;
				}
			}

			.logo{
				width: 15%;
				a{
					padding: 1% 0;
					width: 60%;
					display: flex;
					align-items: center;
					margin: 0 auto;
					height: 100%;
					>img{
						width: auto;
						height: 4vw;
					}
				}
				&:hover, &.active{
					border-bottom: .2vw solid transparent;
				}
			}
		}
	}

	.nav_mobile{
		display: none;
		@media screen and (max-width: $mobile){
			display: block;
			background: #1A1A1A;
		}
		.nav_top{
			display: flex;
			padding: 1%;
			align-items: center;
			position: relative;
			width: 100%;
			box-shadow: 0 5px 6px $accent-dark ;
			justify-content: center;
			a{
				color: $white;
				text-decoration: none !important;
				outline: 0 !important;
				font-size: 4.5vw;
			}
			img{
				width: 15%;
			}
			p{
				width: 65%;
				margin: 0 3%;
				font-size: 5vw;
			}

		}
		.nav_bottom{
			background: $navy;
		}
		ul{
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-between;
			li{
				width: calc(100%/4);
				font-size: 4vw;
				border-bottom: 1vw solid transparent;
				text-align: center;
				padding: 3% 0;
				a{
					color: $white;
					text-decoration: none !important;
					font-family: ExoReg;
					text-transform: uppercase;
				}

				&.active{
					border-bottom:  1vw solid $accent;
				}
			}
		}
	}
}

.acc{
	padding:0;
	margin: 0 .5%;
    width: calc(100%/7);
    height: 100%;
    border-bottom: .2vw solid transparent;
    display: table;
    position: relative;
	&_box{
		width: 25%;
		margin: 0 auto;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		div{
			display: block !important;
			text-decoration: none !important;
			span{				
				color: $white;
				// border-radius: 50%;
				// padding: 8% 13%;
				// display: inline-block;
				// background: transparent linear-gradient(180deg, $accent 0%, $accent-dark 100%) 0% 0% no-repeat;
				@media screen and (max-width: $mobile){
					font-size: 1.5rem;
				}
			}
		}

		.nick{
			background: transparent linear-gradient(180deg, #c092f8 0%, #863ec4 100%) 0% 0% no-repeat;
			width: 2rem;
			border-radius: 50%;
			text-align: center;
			height: 2rem;
			display: flex !important;
			align-items: center;
			justify-content: center;
			font-size: 1.1rem;
			@media screen and (max-width: $mobile){
				width: 85%;
				height: 3rem;
			}
		}
		.subnav{
			display: none;
			position: absolute;
			background: $navy;
			border: 1px solid $white;
			padding: 0;
			margin: 0;
			bottom: -60%;
			@media screen and (max-width: $mobile){
			    bottom: initial;
    			top: 200%;
    			right: -10%;
			}
			&.active{
				display: block;
			}
			li{
				text-align: right;
				display: block !important;
				width: 100% !important;
				border-bottom:  0 !important;
				a,div{
					display: block !important;
					padding: .3vw 1vw !important;
					cursor: pointer;
					color: $white;
					@media screen and (max-width: $mobile){
						font-size: 4.5vw !important;
						padding: 1vw 3vw !important;
					}
				}
				div{
					// margin-top: .3vw;
				}
				&:hover, &.active{
					border-bottom: 0 solid transparent !important;
				}
			}
		}
	}
	&:hover, &.active{
		border-bottom: .2vw solid transparent !important;
	}
}
