.custom-overlay{
	padding: 0;
}
.custom-modal{
	padding: 2%;
    min-width: 30%;
    max-width: 80%;
    text-align: center;
    background: $navy;

    @media screen and (max-width: $mobile){
	    padding: 10% 5%;
	    max-width: 95%;
    }

    &.custom-rules{
    	width: 70%;
	    height: 70%;
	    >div,.popup_wrapper,embed{
	    	width: 100%;
	    	height: 100%;
	    }
    }
}

.custom-close{
	filter: invert(1);
	outline: 0 !important;
}

.popup-reg-btn {
	display: flex;
	align-items:center;
	justify-content: center;
	font-family: RobotoMed;
	margin-top: 10%;

	@media screen and (max-width: $mobile) {
		padding: 8% 0;
		background: $navy;
	}

	.btn-use-team, .btn-new-team {
		flex-basis: 50%;
		cursor: pointer;
		color: $black;
		border: 1px solid transparent;
		text-align: center;
		padding: 2% 0;
		margin: 0 2%;
		background: $accent;
		text-decoration: none !important;
		outline: 0 !important;
	
		@media screen and (max-width: $mobile) {
			font-size: 5vw;
			padding: 3% 2%;
		}
	}

	.btn-new-team {
		border: 1px solid $accent;
		color: $accent;
		background: transparent;
	}
}

.form-popup {
	margin: 8% 2% 0;
	display: flex;

	.form-option {
		width: 100%;
		padding: 2%;
		border: 1px solid $white;
		color: $white;
		background-color: #354165;
	}
}

.popup_wrapper{
	font-family: RobotoReg;
	font-size: 1.1vw;
    @media screen and (max-width: $mobile){
    	font-size: 4vw;
	}
	.btn_ok{
		font-size: 1vw;
		margin: 5% auto 0;
		background: transparent linear-gradient(180deg, $accent 0%, #234AB5 100%) 0% 0% no-repeat;
		display: inline-block;
		padding: 2% 5%;
		border-radius: 5px;
		color: $white;
		cursor: pointer;
	    @media screen and (max-width: $mobile){
			font-size: 4vw;
			padding: 2% 8%;
	    }
	}
}