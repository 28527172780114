.video{
	display: none;
	width: 100%;
	position: relative;
	text-align: center;
	margin-top: -3%;
	margin-bottom: 1%;
	@media screen and (max-width: $mobile){
		margin-top: 0;
	}
	.bg{
		width: 68%;
		@media screen and (max-width: $mobile){
			display: none;
		}
	}

	.video_box{
	    width: 34%;
	    height: 75%;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    border-width: .2vw .3vw;
	    border-color: $accent-dark;
	    border-style: solid;
		@media screen and (max-width: $mobile){
			width: 100%;
			height: 33vh;
			position: relative;
			border-width: 1vw 0;
	    }
	}
}
