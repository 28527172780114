.schedule{
	position: relative;
	overflow: hidden;
	&_box{
		width: 60%;
		margin: 0 auto;
		padding: 0 5%;
	}

	&_list{
		.list{
			margin-bottom: 4%;
		}
	}
}

.d-none{
	display: none !important;
}
div.schedule_list:nth-child(3) > div:nth-child(3){
	display:none;	
}
div.schedule_list:nth-child(3) > div:nth-child(4){
	display:none;	
}
div.schedule_list:nth-child(4) > div:nth-child(3){
	display:none;	
}
div.schedule_list:nth-child(4) > div:nth-child(4){
	display:none;	
}

.schedule_content{
	padding: 0;
	.schedule_list:nth-child(1){
		display:none;
	}

	.tempSchedule {
		.list {
			padding: 4% 2%;
			border-bottom: 1px solid $accent;

			&:last-child {
				border: 0;
			}

			img {
				width: 100%;
			}
		}
	}

	.schedule_list{
		padding: 0 5% 5%;
		&:last-child{
			margin-bottom: 0;

		}
	}
}
