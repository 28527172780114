.about_content,.content_pages{
	padding: 5% 0 10%;
	position: relative;
	overflow: hidden;
	min-height: 90vh;
	@media screen and (max-width: $mobile){
		padding: 35% 0 20%;
	}
	.about_box,.content_pages_box{
		width: 60%;
		margin: 0 auto;
		padding: 0 5%;
		@media screen and (max-width: $mobile){
			width: 100%;
		}
		.title{
			display: none;
		}
		.title_content{
			font-family: Euro;
			text-align: center;
			font-size: 2.5vw;
			margin-bottom: 2%;
			@media screen and (max-width: $mobile){
				font-size: 6.5vw;
				padding-top: 5%;
			}
		}
		.about_tab{
			margin-bottom: 5%;
		}

		.schedule_list{
			padding-bottom: 5%;
			border-bottom: 1px solid $accent;
			margin-bottom: 5%;
		}
	}

}
.rules_about{
	width: 60%;
	margin: 0 auto;	
	border-top: 1px solid $accent;
	border-bottom: 1px solid $accent;
	padding: 2% 0;
	@media screen and (max-width: $mobile){
		width: 100%;
	}
	

}