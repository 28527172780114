.standing {
	padding: 4% 0 !important;

	.desktop {
		@media screen and (max-width: $mobile){
			display: none;
		}	
	}

	.mobile {
		display: none;

		@media screen and (max-width: $mobile){
			display: block;
		}

		margin-bottom: 5%;
	}

	@media screen and (max-width: $mobile){
		padding: 4% 7% !important;
	}

	.title {
		padding: 0 0 4% 7%;
	}

	> img {
		width: 100%;
	}
}