.teams_pages{
	.teams_content{
		margin: 2% 0 0;
		.teams_list{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.box_teams{
				width: calc(100%/5.44);
				height: 10.5vw;
				margin-bottom: 2%;
				@media screen and (max-width: $mobile){
					width: calc(100%/2.12);
					height: 25vh;
					margin-bottom: 5%;
				}
				// &:nth-child(4n){
				// 	// margin-right: 0;
				// 	@media screen and (max-width: $mobile){
				// 		margin-right: 5%;
				// 	}
				// }

				// &:nth-child(2n){
				// 	@media screen and (max-width: $mobile){
				// 		margin-right: 0;
				// 	}	
				// }
			}
		}
	}
}