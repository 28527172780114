.slick-arrow{
	display: none !important;
}

.slick-dots li{
	width: 1vw;
	height: 1vw;
	@media screen and (max-width: $mobile){
		width: 5vw;
		height: 5vw;
	}
	button{
		width: 100%;
		height: 100%;
		padding: 0;
		&:before{
			color: #FFFFFF;
			opacity: .5;
			width: 100%;
			height: 100%;
			font-size: .8vw;
			@media screen and (max-width: $mobile){
				font-size: 5vw;
			}
		}
	}
	&.slick-active{
		button{
			&:before{
				color: $accent;
			}
		}
	}
}

.schedule {
	padding: 4% 0 !important;

	@media screen and (max-width: $mobile){
		padding: 4% 7% !important;
	}

	.title {
		margin: 0% 7% 4%;
	}

	> img {
		width: 100%;
	}
}

div.schedule_list > div:nth-child(3),
div.schedule_list > div:nth-child(4){
	display:none;	
}
.schedule_list{
	.list{
		margin: 1.5% 0;
		font-family: ExoReg;
		.date{
			margin-bottom: 1%;
			color: $white;
			text-transform: uppercase;
		}
		&_box{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 2% 10%;
			border: 1px solid #5B81AF;
			background: url('~assets/img/bg_Container_Braket_Schedule.png') no-repeat left center;
			background-size: 190%;
			@media screen and (max-width: $mobile){
				padding: 5%;
				font-size: 4vw;
			}
			.btn_bracket{
				cursor: pointer;
				text-transform: uppercase;
				padding: 1% 7%;
				border: 1px solid $white;
				border-radius: 5px;
				font-size: .9vw;
				color: $white;
				text-decoration: none !important;
				@media screen and (max-width: $mobile){
					font-size: 4vw;
					padding: 1% 2%;
				}
				&:hover{
					background: $white;
					color: $black;
				}
			}
		}
	}
}
