:root {
	--img_bg_desktop: url('~assets/img/Desktop_Heropage_KV.jpg');
	--img_bg_mobile: url('~assets/img/Mobile_Heropage_KV.jpg');
}
.section1{	
	// background-image: 
	// 	url('~assets/img/Ornate_DottedGradient.png')
	// 	,var(--img_bg_desktop);
	// background-position: center 41vh, center center;
	// background-size: 100%, cover;
	background-image: var(--img_bg_desktop);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 98vh;
	position: relative;
	@media screen and (max-width: $mobile){
		// background-image: url('~assets/img/Ornate_DottedGradient.png'), var(--img_bg_mobile);
		// background-position: center 50vh, center 6vh;
		// background-size: cover, cover;
		background-image: var(--img_bg_mobile);
		background-position: center 6vh;
		background-size: cover;
	}

	&_content{
		text-align: center;
		position: absolute;
		bottom: 0;
		width: 100%;
		.big_logo{
			width: 20%;
			margin: 156px auto;
			@media screen and (max-width: $mobile){
				width: 56%;
			}
			img{
				display:none;
			}
		}

		.text{
			font-size: 1.5vw;
			margin: 1% 0;
			@media screen and (max-width: $mobile){
				font-size: 4vw;
				margin: 5% 0;
			}
			.img-big{
				width: 50%;
				@media screen and (max-width: $mobile){
					width: 80%;
				}
			}
			p{
				width: 25%;
				margin: 0 auto;
				text-transform: uppercase;
				font-family: ExoReg;
				text-shadow: 2px 2px 5px #000;
				@media screen and (max-width: $mobile){
					width: 70%;
				}
			}
			&_big{
				margin-top: 1%;
				font-size: 3.3vw;
				color: $accent;
				font-family: Euro;
				line-height: .9;
				text-transform: uppercase;
				text-shadow: 2px 3px 3px #1E8F8E;
				span{
					display: block;
					color: $white;
					font-size: 5.3vw;
				}
				@media screen and (max-width: $mobile){
					margin-top: 5%;
					font-size: 9.5vw;
					text-shadow: 1px 2px 2px #1E8F8E;
					line-height: 1;
					span{
						font-size: 15vw;
					}

				}
			}
		}

		.img-regis{
			width: 50%;	
			//-webkit-filter: drop-shadow(0 0 30px #B20000);filter: drop-shadow(0 0 30px #B20000);
			-webkit-animation: glowing 1500ms infinite;
			-moz-animation: glowing 1500ms infinite;
			-o-animation: glowing 1500ms infinite;
			animation: glowing 1500ms infinite;
		}
		@-webkit-keyframes glowing {
			0% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
			50% { -webkit-filter: drop-shadow(0 0 10px black);filter: drop-shadow(0 0 10px black) }
			100% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
		}

		@-moz-keyframes glowing {
			0% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
			50% { -webkit-filter: drop-shadow(0 0 10px black);filter: drop-shadow(0 0 10px black) }
			100% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
		}

		@-o-keyframes glowing {
			0% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
			50% { -webkit-filter: drop-shadow(0 0 10px black);filter: drop-shadow(0 0 10px black) }
			100% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
		}

		@keyframes glowing {
			0% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
			50% { -webkit-filter: drop-shadow(0 0 10px black);filter: drop-shadow(0 0 10px black) }
			100% { -webkit-filter: drop-shadow(0 0 10px yellow);filter: drop-shadow(0 0 10px yellow) }
		}

		.btn_regis{
			display: inline-block;
			border-radius: 5px;
			font-family: ExoMed;
			font-weight: bold;
			letter-spacing: 1px;
			font-size: 1.5vw;
			@media screen and (max-width: $mobile){
				font-size: 5vw;
				padding: 3% 13% 2%;
			}
		}
		
		.sponsor {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			gap: 1rem;

			img {
				height: 5rem;
				@media screen and (max-width: $mobile){
					height: 15vw;
				}
			}
		}

		.scroll_to{
			margin: .7% auto;
			display: block;
			@media screen and (max-width: $mobile){
				width: 7%;
				margin: 2% auto 5%;
			}
		}
		
		.sponsor_list{
			color: transparent;
			// border: 1px solid $accent;
			// border-left: 0;
			// border-right: 0;
			width: 100%;
			background: $navy;
			padding: 1.3%;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $mobile){
				padding: 3%;
			}
			.main{
				margin: 0 2%;
			}
		}
	}

}
